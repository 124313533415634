import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com"; // Import emailjs
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";
import { useDocTitle } from "../components/CustomHook";
import Notiflix from "notiflix";

const Contact = () => {
  useDocTitle("ENVIR");

  // Scroll to the top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  // const [errors, setErrors] = useState([]);

  // const clearErrors = () => {
  //   setErrors([]);
  // };

  const clearInput = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setMessage("");
  };

  const sendEmail = (e) => {
    e.preventDefault();
    document.getElementById("submitBtn").disabled = true;
    document.getElementById("submitBtn").innerHTML = "Loading...";

    const templateParams = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      message: message,
    };

    emailjs
      .send(
        "service_ury777s", // Your service ID
        "template_1b86dkk", // Your template ID
        templateParams,
        "AX4NkUz5R-onX25d3" // Your user ID (public key)
      )
      .then((response) => {
        document.getElementById("submitBtn").disabled = false;
        document.getElementById("submitBtn").innerHTML = "Send Message";
        clearInput();
        Notiflix.Report.success(
          "Success",
          "Message sent successfully!",
          "Okay"
        );
      })
      .catch((error) => {
        document.getElementById("submitBtn").disabled = false;
        document.getElementById("submitBtn").innerHTML = "Send Message";
        Notiflix.Report.failure(
          "An error occurred",
          "Failed to send message",
          "Okay"
        );
      });
  };

  return (
    <>
      <div>
        <NavBar />
      </div>
      <div
        id="contact"
        className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24"
      >
        <div className="container mx-auto my-8 px-4 lg:px-20">
          <div className="flex flex-col lg:flex-row items-stretch">
            {/* Contact Form Section */}
            <div className="w-full lg:w-9/12 bg-white p-8 my-4 md:px-12 rounded-2xl shadow-2xl">
              <div className="flex">
                <h1 className="font-bold text-center lg:text-left text-blue-900 uppercase text-4xl">
                  Send us a message
                </h1>
              </div>
              <form onSubmit={sendEmail}>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                  <div>
                    <input
                      name="first_name"
                      className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                      type="text"
                      placeholder="First Name*"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      // onKeyUp={clearErrors}
                    />
                  </div>
                  <div>
                    <input
                      name="last_name"
                      className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                      type="text"
                      placeholder="Last Name*"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      // onKeyUp={clearErrors}
                    />
                  </div>
                  <div>
                    <input
                      name="email"
                      className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                      type="email"
                      placeholder="Email*"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      // onKeyUp={clearErrors}
                    />
                  </div>
                  <div>
                    <input
                      name="phone"
                      className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                      type="number"
                      placeholder="Phone*"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      // onKeyUp={clearErrors}
                    />
                  </div>
                </div>
                <div className="my-4">
                  <textarea
                    name="message"
                    placeholder="Message*"
                    className="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    // onKeyUp={clearErrors}
                  ></textarea>
                </div>
                <div className="my-2 w-1/2 lg:w-2/4">
                  <button
                    type="submit"
                    id="submitBtn"
                    className="uppercase text-sm font-bold tracking-wide bg-gray-500 hover:bg-blue-900 text-gray-100 p-3 rounded-lg w-full 
                                    focus:outline-none focus:shadow-outline"
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>

            {/* Sidebar Section */}
            <div className="w-full lg:w-3/12 bg-blue-900 text-white p-8 my-4 rounded-2xl shadow-2xl lg:ml-6 flex flex-col justify-center">
              <h2 className="text-2xl font-bold mb-4">Contact Informations</h2>
              <p className="mb-4">
                <span className="font-semibold">Address:</span> Tripoli,
                Lebanon, Abou Samra, Al Nour, Maitham Street
              </p>
              <p className="mb-4">
                <span className="font-semibold">Phone:</span> +961 70 890028
              </p>
              <p className="mb-4">
                <span className="font-semibold">Owner:</span> Amer Jarad
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;

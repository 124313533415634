import React from "react";
// import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30">
          {/* Top area: Blocks */}
          <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-t border-gray-200 lg:ml-11">
            {/* 1st block */}
            <div className="col-span-12 lg:col-span-4">
              <div className="box-border border-b-4 border-blue-900 p-8 bg-gray-200 text-gray-600 text-center rounded-lg xl:w-80 mx-auto">
                <h3 className="font-bold text-4xl mb-4">ENVIR</h3>
                <div className="text-md font-medium text-gray-600">
                  <h5>Amer Jrad</h5>
                  <p>Tripoli, Lebanon,</p>
                  <p>Abou Samra,</p>
                  <p>Al Nour, Maitham Street</p>
                </div>
              </div>
            </div>

            {/* 2nd block */}
            <div className="col-span-6 md:col-span-6 lg:col-span-1 ml-7 mx-auto">
              <h6 className="text-[#013289] text-xl font-bold mb-4">LINKS</h6>
              <ul className="text-md">
                <li className="mb-2">
                  <HashLink
                    to="#about"
                    className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    About
                  </HashLink>
                </li>
                <li className="mb-2">
                  <HashLink
                    to="#services"
                    className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Services
                  </HashLink>
                </li>
                <li className="mb-2">
                  <HashLink
                    to="#portfolio"
                    className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Projects
                  </HashLink>
                </li>
              </ul>
            </div>

            {/* 3rd block */}
            <div className="col-span-6 md:col-span-6 lg:col-span-4 mx-auto">
              <h6 className="text-[#013289] text-xl font-bold mb-4">
                OUR SERVICES
              </h6>
              <ul className="text-md">
                <li className="mb-2">
                  {/* <Link
                    to="#"
                    className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  > */}
                  Water & Wastewater Treatment
                  {/* </Link> */}
                </li>
                <li className="mb-2">
                  {/* <Link
                    to="#"
                    className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  > */}
                  Industrial Wastewater Treatment
                  {/* </Link> */}
                </li>
                <li className="mb-2">
                  {/* <Link
                    to="#"
                    className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  > */}
                  Seawater & River Water Treatment
                  {/* </Link> */}
                </li>
                <li className="mb-2">
                  {/* <Link
                    to="#"
                    className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  > */}
                  Consulting & Management
                  {/* </Link> */}
                </li>
              </ul>
            </div>

            {/* 4th block */}
            <div className="col-span-12 text-center mx-auto lg:col-span-3 font-bold uppercase text-blue-900">
              <div className="text-xl mb-6">Contact us.</div>

              <div className="text-md font-medium mb-6">
                Contact us via WhatsApp
              </div>
              <a
                href="https://wa.me/+96170890028" // Replace with the actual number
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-4 py-2 bg-blue-900 text-white rounded-md hover:bg-green-600 transition"
              >
                {/* WhatsApp Logo SVG */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  width="24"
                  height="24"
                  className="mr-2" // Margin for spacing
                >
                  <path d="M12 0C5.373 0 0 5.373 0 12c0 2.3.679 4.436 1.834 6.284L0 24l5.83-1.83A11.967 11.967 0 0012 24c6.627 0 12-5.373 12-12S18.627 0 12 0zm6.485 17.486l-1.496.631c-1.44.608-2.953.885-4.363.885-2.367 0-4.793-.763-6.823-2.182l-1.098 1.098 1.098-1.098c-1.128-.794-2.098-1.867-2.936-3.166l-1.176.1C1.642 16.662 6.126 20 12 20c1.463 0 2.872-.2 4.252-.576l-1.767-1.938c-1.135.424-2.332.636-3.485.636-4.953 0-9-4.047-9-9 0-2.474 1.99-4.464 4.464-4.464 1.003 0 2.18.276 3.417.762l1.176-.1c-.904-1.455-2.241-2.525-3.763-3.069C5.648 4.274 3.616 7.006 3.616 10.13c0 4.062 3.091 7.65 7.122 7.65 1.291 0 2.545-.316 3.661-.823l1.876 1.88a11.93 11.93 0 00-2.118-.219c-1.894 0-3.868.392-5.598 1.106l.632 1.497a10.935 10.935 0 002.196.297c5.25 0 9.493-4.253 9.493-9.493 0-1.458-.28-2.849-.777-4.117z" />
                </svg>
                <span>WhatsApp</span>
              </a>
            </div>
          </div>

          <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
              <div className="text-sm text-gray-200 font-semibold py-1">
                Copyright &copy; {new Date().getFullYear()}
                {"  "}
                ENVIR . All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;

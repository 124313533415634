import React from "react";

const Services = () => {
  return (
    <div id="services" className="bg-gray-100 py-12">
      <section data-aos="zoom-in-down">
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
            Services
          </h2>
          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-blue-900"></div>
          </div>
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
            We are deeply committed to the growth and success of our clients.
          </h2>
        </div>

        <div className="px-12" data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
            {/* Water & Wastewater Treatment */}
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-5 flex flex-col items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="96" // Increased width
                height="96" // Increased height
                viewBox="0 0 24 24"
                className="fill-current text-blue-900 mb-4" // Added margin bottom for spacing
              >
                <path d="M5 22h14c1.104 0 2-.896 2-2V4c0-1.104-.896-2-2-2H5c-1.104 0-2 .896-2 2v16c0 1.104.896 2 2 2zm0-18h14v16H5V4z"></path>
              </svg>
              <h2 className="font-semibold text-2xl text-center">
                Water & Wastewater Treatment
              </h2>
              <p className="text-md font-medium text-center">
                Benefits of biological and chemical package treatment units,
                suitable for various applications.
              </p>
            </div>

            {/* Industrial Wastewater Treatment */}
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-5 flex flex-col items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="96" // Increased width
                height="96" // Increased height
                viewBox="0 0 24 24"
                className="fill-current text-blue-900 mb-4" // Added margin bottom for spacing
              >
                <path d="M6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4v-2h2v2zm6 8h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2v-2h2v2zm6 8h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2v-2h2v2zm-6-6H6v-2h6v2zm6 0h-2V6h2v2z"></path>
              </svg>
              <h2 className="font-semibold text-2xl text-center">
                Industrial Wastewater Treatment
              </h2>
              <p className="text-md font-medium text-center">
                Tailored solutions for different industries, ensuring compliance
                with environmental regulations.
              </p>
            </div>

            {/* Seawater & River Water Treatment */}
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-5 flex flex-col items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="96" // Increased width
                height="96" // Increased height
                viewBox="0 0 24 24"
                className="fill-current text-blue-900 mb-4" // Added margin bottom for spacing
              >
                <path d="M21 14c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zm-4.586-2.586l1.414-1.414c.632-.632.632-1.664 0-2.296-.632-.632-1.664-.632-2.296 0l-1.414 1.414c-.632.632-.632 1.664 0 2.296.632.632 1.664.632 2.296 0zM6 10h-.586l1.293-1.293C8.407 7.059 10.136 6 12 6s3.593 1.059 4.293 2.707L18 10h-.586l-1.293 1.293C16.593 12.941 14.864 14 12 14s-4.593-1.059-5.293-2.707L6 10zm11 4c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2zM4 18c0 1.104.896 2 2 2s2-.896 2-2-.896-2-2-2-2 .896-2 2z"></path>
              </svg>
              <h2 className="font-semibold text-2xl text-center">
                Seawater & River Water Treatment
              </h2>
              <p className="text-md font-medium text-center">
                Use of SWRO and lamella treatment technologies for effective
                water purification.
              </p>
            </div>

            {/* Consulting & Management */}
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-5 flex flex-col items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="96" // Increased width
                height="96" // Increased height
                viewBox="0 0 24 24"
                className="fill-current text-blue-900 mb-4" // Added margin bottom for spacing
              >
                <path d="M12 0C5.372 0 0 5.372 0 12c0 6.627 5.372 12 12 12s12-5.373 12-12C24 5.372 18.628 0 12 0zm0 22c-5.488 0-10-4.512-10-10S6.512 2 12 2s10 4.512 10 10-4.512 10-10 10zm1-14h-2v4h2V8zm0 6h-2v2h2v-2z"></path>
              </svg>
              <h2 className="font-semibold text-2xl text-center">
                Consulting & Management
              </h2>
              <p className="text-md font-medium text-center">
                Operational optimization and training provided for personnel and
                ongoing support to ensure compliance and high-quality output.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;

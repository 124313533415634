import React from "react";
import { Link } from "react-router-dom";

const Cta = () => {
  return (
    <div className="w-full flex items-center justify-center text-white cta bg-black">
      <div className="mx-8 w-full h-auto lg:h-96 text-center lg:text-left py-8 px-4 md:px-12 flex flex-col lg:flex-row lg:justify-between items-center">
        <div className="w-full flex flex-col items-center lg:items-start lg:flex-row lg:justify-around">
          <div className="mb-6 lg:mb-0 lg:mr-6">
            <p className="text-2xl md:text-4xl font-bold mb-4">
              Transforming Engineering Solutions
            </p>
            <p className="text-lg md:text-2xl mb-4">
              Partner with Amer Jarad Engineering Office for innovative and
              sustainable solutions in electrical, mechanical, and plumbing
              services.
            </p>
            <p className="text-lg md:text-2xl">
              Get in touch and let us build something amazing{" "}
              <span className="font-black">Together!</span>
            </p>
          </div>

          {/* Button Section */}
          <div className="w-full lg:w-72 pt-6 lg:pt-0 lg:mx-12 flex justify-center lg:justify-start">
            <Link
              to="/contact"
              className="bg-transparent border hover:bg-blue-900 hover:border-blue-800 text-white text-center rounded-lg px-8 py-3 flex items-center group"
            >
              Send a message
              <svg
                className="w-5 h-5 ml-1 group-hover:translate-x-2 duration-500 ease-in"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M12 2C11.4477 2 11 2.44772 11 3C11 3.27614 11.1119 3.53302 11.3083 3.72546L12 4.5L12.6917 3.72546C12.8881 3.53302 13 3.27614 13 3C13 2.44772 12.5523 2 12 2ZM12 6C12.5523 6 13 6.44772 13 7C13 7.27614 12.8881 7.53302 12.6917 7.72546L12 8.5L11.3083 7.72546C11.1119 7.53302 11 7.27614 11 7C11 6.44772 11.4477 6 12 6ZM16.5 12C16.5 14.4853 14.4853 16.5 12 16.5C9.51472 16.5 7.5 14.4853 7.5 12C7.5 9.67157 9.11438 7.40641 12 4.64645C14.8856 7.40641 16.5 9.67157 16.5 12ZM12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14ZM12 18C13.6569 18 15 16.6569 15 15H17C17 17.7614 14.7614 20 12 20C9.23858 20 7 17.7614 7 15H9C9 16.6569 10.3431 18 12 18Z"
                  clipRule="evenodd"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cta;

import React from "react";
import { useDocTitle } from "../components/CustomHook";
import { FaBuilding, FaWater, FaIndustry } from "react-icons/fa"; // Icons for projects
import Carousel from "react-multi-carousel";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import "react-multi-carousel/lib/styles.css"; // Carousel styles

const Portfolio = () => {
  useDocTitle("ENVIR");
  const navigate = useNavigate(); // Initialize navigate for navigation

  const projects = [
    {
      name: "Qobayat Wwtp extension",
      icon: <FaIndustry />,
    },
    {
      name: "El Mirador Minieh Water and Wastewater Network",
      icon: <FaWater />,
    },
    {
      name: "Luna Residence WWTP",
      icon: <FaBuilding />,
    },
    {
      name: "Al Gharbieh– Kharyout - Desalination plant",
      icon: <FaWater />,
    },
    {
      name: "Miramar Tourism Station WWTP",
      icon: <FaBuilding />,
    },
    {
      name: "Wwtp for Taqah Longue Beach - Salalah - Sultanate of Oman",
      icon: <FaIndustry />,
    },
    {
      name: "Al Manahej Al Alamiya WWTP",
      icon: <FaIndustry />,
    },
    {
      name: "Al Turath Complex Residential WWTP",
      icon: <FaBuilding />,
    },
    {
      name: "Al Zaid, Salalah WWTP",
      icon: <FaWater />,
    },
  ];

  // Configuration for carousel responsiveness
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3, // Show 3 projects per page
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2, // Show 2 projects on tablets
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1, // Show 1 project on mobile
    },
  };

  const handleLearnMore = () => {
    navigate("/contact"); // Redirect to the contact page
  };

  return (
    <>
      <div className="bg-gray-100 py-12" id="portfolio">
        <section data-aos="zoom-in-down">
          <div className="my-4 py-4">
            <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
              Our Recent Projects
            </h2>
            <div className="flex justify-center">
              <div className="w-24 border-b-4 border-blue-900"></div>
            </div>
            <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
              We take pride in our work and the projects we've completed.
            </h2>
          </div>

          {/* Project Carousel */}
          <div className="px-12">
            <Carousel
              responsive={responsive}
              swipeable={true}
              draggable={true}
              showDots={false} // Show dots for navigation
              infinite={true} // Enable infinite scroll
              autoPlay={false} // Auto-play disabled for manual control
              keyBoardControl={true}
              transitionDuration={500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style mt-4" // Add margin-top to dots
              itemClass="carousel-item-padding" // Custom class for item padding
              customTransition="transform 300ms ease-in-out"
              renderDotsOutside={true}
            >
              {projects.map((project, index) => (
                <div
                  key={index}
                  className="bg-white transition-all ease-in-out duration-300 overflow-hidden text-gray-700 rounded-lg shadow-lg flex flex-col justify-between group hover:shadow-2xl hover:transform hover:scale-105" // Flexbox for layout
                  style={{
                    width: "100%",
                    height: "300px",
                    borderRadius: "12px",
                    padding: "16px", // Padding to maintain spacing inside
                  }}
                >
                  <div className="text-center">
                    <div className="text-6xl text-blue-900 mb-4">
                      {project.icon}
                    </div>
                    <h2 className="font-semibold text-black text-xl group-hover:text-blue-900 text-center transition duration-300">
                      {project.name}
                    </h2>
                  </div>
                  <button
                    className="mt-auto py-2 px-6 bg-blue-900 text-white rounded-full group-hover:bg-white group-hover:text-blue-900 transition duration-300 border border-blue-900 hover:border-blue-900"
                    onClick={handleLearnMore} // Navigate to contact page on click
                  >
                    Learn More
                  </button>
                </div>
              ))}
            </Carousel>
          </div>
        </section>
      </div>
    </>
  );
};

export default Portfolio;

import React from "react";
import { useDocTitle } from "../components/CustomHook";

const Clients = () => {
  useDocTitle("ENVIR");

  return (
    <>
      <div className="bg-gray-100 py-12" id="clients">
        <section data-aos="zoom-in-down">
          <div className="my-4 py-4">
            <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
              Environmental Commitment
            </h2>
            <div className="flex justify-center">
              <div className="w-24 border-b-4 border-blue-900"></div>
            </div>
            <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
              Our commitment to sustainability and quality in every project.
            </h2>
          </div>

          <div className="m-auto max-w-6xl p-4 md:p-12 h-5/6">
            <div
              className="flex flex-col py-8 justify-between text-left"
              data-aos="fade-up"
            >
              {/* Sustainability Practices */}
              <div className="my-6">
                <h4 className="text-2xl font-semibold text-blue-900">
                  Sustainability Practices
                </h4>
                <p className="text-xl text-gray-600 font-semibold">
                  We prioritize efficient resource use and waste management,
                  ensuring minimal impact on the environment.
                </p>
              </div>

              {/* Quality Assurance */}
              <div className="my-6">
                <h4 className="text-2xl font-semibold text-blue-900">
                  Quality Assurance
                </h4>
                <p className="text-xl text-gray-600 font-semibold">
                  We are committed to maintaining high-quality standards and
                  continuous improvement in all projects.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Clients;
